<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">造品赋能·方案详情</div>
      <div class="sub-title">造品赋能增长策略</div>
      <div class="large-paragraph-describe">
        全球蛙联合国内知名创新设计集团洛可可，共同创立“造悟说”造品平台。造悟说构建以用户参与、数据驱动的产品创新与全渠道营销体系，进行商品结构优化及爆品营销，从而高效链接供销两端。造悟说合作全国300+优质商超，助力其自有品牌爆品打造，并通过联手全国品牌，将商超与其联名，打造差异化新品、超级大单品，从而驱动零售商超增长原动力，激发增长新活力！
      </div>
    </div>
    <img class="large-image-h278" src="@/image/retail/img_zyy_produce@3x.png" />
    <img
      class="large-image-h260"
      v-for="src in subscriptImages"
      :key="src"
      :src="src"
    />
    <img
      class="large-image-h582"
      src="@/image/retail/img_explosives_produce@3x.png"
    />
    <div class="func-module-container">
      <div class="func-title">造品赋能·应用案例</div>
      <div class="func-sub-title">
        造悟说助力零售商打造自有品牌爆品，实现新增长
      </div>
      <div class="func-describe">
        2022年3月，造悟说携手美特好开展自有品牌合作，首款自有品牌——海南岛椰汁一经推出，短时间内销售破百万，成为美特好同一时间段销售额、毛利率和周转率均为饮品类第一的明星单品。造悟说联合美特好乘胜追击，陆续打造椰汁、纯牛奶、枣夹核桃仁、洗衣液等数十款自有品牌商品，不仅极大程度的丰富了美特好自有品牌商品品类，也使得自有品牌在美特好整体业绩占比中逐年提升。
      </div>
    </div>
    <div class="explosives-power-footer-image-container">
      <img
        class="large-image-h408"
        src="@/image/retail/img_yz_produce@3x.png"
      />
      <img
        class="large-image-h408"
        src="@/image/retail/img_dm_produce@3x.png"
      />
      <img
        class="large-image-h396"
        src="@/image/retail/img_sp_produce@3x.png"
      />
    </div>
    <action-sheet module="新零售造品赋能">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    subscriptImages: [
      require("@/image/retail/img_brand_produce@3x.png"),
      require("@/image/retail/img_market_produce@3x.png"),
      require("@/image/retail/img_channel_produce@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.large-image-h278,
.large-image-h582 {
  margin-bottom: 60px;
}
.large-image-h260 {
  margin-bottom: 40px;
}
.large-image-h436 {
  margin-bottom: 20px;
}
.large-image-h408 {
  margin-top: 40px;
}
.explosives-power-footer-image-container{
  background-color: $bgColorf2f5fa !important;
}
.func-module-container {
  padding-bottom: 0 !important;
}
.large-image-h362,
.large-image-h640 {
  margin-bottom: 120px;
}
</style>
